import favicon from "../assets/favicon.ico";
import { Helmet } from "react-helmet";
import React from "react";

interface Props {
  title?: string;
}

const SEO: React.FC<Props> = ({ title = "" }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    </Helmet>
  );
};

export default SEO;
